import React, { ChangeEvent, useEffect, useState } from 'react';
import { Container, SeparatorLine } from './styles';
import api from '~/services/api';
import { Col, Row } from 'react-bootstrap';
import Loja from '~/components/Loja';
import Parceiros from '~/components/Parceiros';
import { NFe, SelectFornecedor, SelectProps } from './types';
import { InputSelect, InputNumber, InputText } from '~/components/NovosInputs';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { TableNF } from './components/TableTitulos';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { motivoSchema, schema } from './validations/validation';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { BiSearchAlt2 } from 'react-icons/bi';
import { TiCancel } from 'react-icons/ti';
import FooterDefault from '~/components/FooterDefault';
import DefaultLoader from '~/components/DefaultLoader';
import { GridRowId } from '@material-ui/data-grid';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';

const CancelamentoDeNota: React.FC = () => {
  const MySwal = withReactContent(Swal);
  const {
    register,
    control,
    reset,
    getValues,
    clearErrors,
    setError,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [lojas, setLojas] = useState<number[]>([1]);
  const [loader, setLoader] = useState<boolean>(false);
  const [series, setSeries] = useState<SelectProps[]>([
    {
      label: '',
      value: 0,
    },
  ]);
  const [fornecedor, setFornecedor] = useState<SelectFornecedor>({
    label: '',
    value: undefined,
    isInvalid: true,
    isRequired: true,
  });
  const [nf, setNF] = useState<NFe[]>([]);
  const [cod_seq, setCod_seq] = useState<any>();
  const [selectionModel, setSelectionModel] = useState<any>([]);
  const [clearLoja, setClearLoja] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [label, setLabel] = useState<string>('Executar Cancelamento');
  const [tipoRotina, setTipoRotina] = useState<number>(0); // cancelamento = 0

  const formBlank = {
    serie: '',
    num_nf: '',
    motivo: '',
  };

  async function getSeries() {
    try {
      if (lojas.length >= 0) {
        const requests = lojas.map(async (item) => {
          const res = await api.get(`/nf-outras-operacoes/serie/${item}`);
          return res.data;
        });

        const responses = await Promise.all(requests);

        const options: SelectProps[] = responses.flatMap((elem) => {
          const { data, success, message } = elem;
          if (!success) {
            throw new Error(message);
          }
          return data.map((item: any) => ({
            value: item.num_serie,
            label: item.num_serie,
          }));
        });

        setSeries(options);
      }
    } catch (error: any) {
      toast.warn('Nenhuma série cadastrada a loja');
    }
  }

  const handleValidation = () => {
    if (fornecedor.isInvalid || fornecedor.value === undefined) {
      setFornecedor({
        ...fornecedor,
        isRequired: true,
        isInvalid: true,
      });
      return true;
    }
    return false;
  };

  const handleSubmit = async () => {
    try {
      if (handleValidation()) {
        setInit(true);
        return;
      }
      setInit(false);

      const isValid = await trigger();

      if (isValid) {
        setLoading(true);
        const values = getValues();
        const { serie, num_nf } = values;
        const { data } = await api.post(`/nf/buscar`, {
          cod_loja: lojas,
          num_serie: serie,
          num_nf: Number(num_nf),
          cod_pessoa: fornecedor.value,
        });

        if (data.success && data.data) {
          if (data.data.length > 0) {
            const nfData: NFe[] = data.data;
            setIsDisabled(true);

            setNF(nfData);

            // flg_nfe_pendente = true  ->  não transmitida -> inutilização
            // flg_nfe_pendente = false ->  transmitida     -> cancelamento
            if (nfData[0].flg_nfe_pendente) {
              setLabel('Executar Inutilização');
              setTipoRotina(1); // inutilização
              // nesse caso vai para inutilização
              return;
            }
            // pendente false -> cancelamento
            // Chamar microsserviço para verificar se está na sefaz
            await api.post(`/nf/consulta/sefaz`, {
              cod_loja: nfData[0].cod_loja,
              cod_seq_nf: nfData[0].cod_seq_nf,
              num_chave_acesso: nfData[0].num_chave_acesso,
              tipo_operacao: nfData[0].tipo_operacao,
            });

            // selectedNF
            const selectedIds = new Set([nfData[0].cod_seq_nf]);
            const selectedNFS = data.data.filter((nfItem: any) => {
              return selectedIds.has(nfItem.cod_seq_nf);
            });
            setCod_seq([...selectedNFS]);

            setSelectionModel([nfData[0].cod_seq_nf]);
            return;
          }
          setLabel('Executar Inutilização');
          setTipoRotina(1); // inutilização
          toast.warning(
            'Nenhum registro encontrado conforme os critérios informados. Caso queria inutlizar a numeração, informe o motivo e clique em Executar Inutilização.',
          );
        }
      } else {
        toast.warning('Existem informações faltando');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitCancel = async () => {
    try {
      const values = getValues();
      const { motivo } = values;
      const isValid = await motivoSchema.isValid({ motivo });
      if (isValid) {
        if (cod_seq.length > 0) {
          await MySwal.fire({
            title: `Cancelar Nota`,
            text: `Esta ação irá cancelar a nota fiscal. Deseja continuar? \n
          Número da nota fiscal: ${cod_seq[0].num_nf}`,
            showCancelButton: true,
            confirmButtonColor: '#07289e',
            cancelButtonColor: '#ff7b7b',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
          }).then(async (result) => {
            if (result.isConfirmed) {
              setLoader(true);
              const { data } = await api.put(`/nf/cancelar`, {
                motivo,
                cod_seq_nf: cod_seq[0].cod_seq_nf,
                cod_loja: nf[0].cod_loja,
                num_chave_acesso: nf[0].num_chave_acesso,
                tipo_operacao: nf[0].tipo_operacao,
                nf,
              });
              if (!data.success) {
                toast.warn(data.message);
              }
              toast.success(data.message);
              setLoader(false);
              handleReset();
            }
          });
        } else {
          toast.warn(
            'Clique sobre uma NF para selecioná-la para o cancelamento.',
          );
        }
      } else {
        setError('motivo', {
          type: 'manual',
          message: 'Motivo inválido',
        });
        toast.warn('Informe o motivo do cancelamento');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleSubmitInutilizacao = async () => {
    try {
      const values = getValues();
      const { motivo } = values;
      const isValid = await motivoSchema.isValid({ motivo });
      if (isValid) {
        await MySwal.fire({
          title: `Inutilizar Numeração`,
          text: `Numeração não existente na SEFAZ. Deseja Inutilizar? \n
          Processo Irreversível`,
          showCancelButton: true,
          confirmButtonColor: '#07289e',
          cancelButtonColor: '#ff7b7b',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoader(true);
            const serie = getValues('serie');
            const num_nf = getValues('num_nf');

            const { data } = await api.put(`/nf/inutilizar`, {
              motivo,
              cod_loja: nf[0].cod_loja,
              num_serie_nf: serie,
              num_nf,
            });
            if (!data.success) {
              toast.warn(data.message);
            }
            toast.success(data.message);
            setLoader(false);
            handleReset();
          }
        });
      } else {
        setError('motivo', {
          type: 'manual',
          message: 'Motivo inválido',
        });
        toast.warn('Informe o motivo da inutilização');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const handleReset = () => {
    setInit(false);
    setIsDisabled(false);
    reset(formBlank);
    setLojas([1]);
    setFornecedor({
      label: '',
      value: undefined,
      isInvalid: true,
      isRequired: true,
    });
    setNF([]);
    setClearLoja(true);
    setLabel('Executar Cancelamento');
    setTipoRotina(0);
  };

  const onSelectedNF = (selected: GridRowId[]) => {
    const selectedIds = new Set(selected);
    const selectedNFS = nf.filter((nfItem) => {
      return selectedIds.has(nfItem.cod_seq_nf);
    });
    setCod_seq([...selectedNFS]);
  };

  useEffect(() => {
    setClearLoja(false);
    async function reqApi() {
      await getSeries();
    }
    // reqApi();
  }, [lojas]);

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  return (
    <Container>
      <h3>Cancelamento de Nota Fiscal</h3>
      <SeparatorLine />
      <Row>
        <Row>
          <Col>
            <Loja
              isMulti
              resetLojas={clearLoja}
              disabled={isDisabled}
              onChange={(val: any) => {
                if (val.length === 0) {
                  setLojas([1]);
                } else {
                  setLojas(val);
                }
              }}
              selectedLoja={lojas}
            />
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <Parceiros
              value={fornecedor}
              tipos={['Cliente', 'Fornecedor']}
              isDisabled={isDisabled}
              onChange={(value: SelectFornecedor) => {
                setFornecedor({
                  label: value.label,
                  value: value.value,
                  isInvalid: false,
                  isRequired: true,
                });
              }}
              isRequired={fornecedor.isRequired}
              iniInicializado={init}
              setInvalid={fornecedor.isInvalid}
              clearOptions={fornecedor.value === undefined}
            />
          </Col>
          <Col md={4} lg={2}>
            <InputText
              label="Série"
              maxLength={50}
              caseInput="upper"
              placeholder=""
              name="serie"
              disabled={isDisabled}
              register={register}
              control={control}
              onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                setValue('serie', value);
                clearErrors('serie');
                // handleChangeNumSerie(newValue, isInvalidParam);
              }}
              isError={!!errors.serie}
            />
          </Col>
          <Col md={4} lg={2}>
            <InputNumber
              label="Nº NF:"
              maxLength={9}
              disabled={isDisabled}
              max={31}
              min={1}
              placeholder="0"
              name="num_nf"
              register={register}
              control={control}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                if (value.length <= 9) {
                  setValue('num_nf', Number(value));
                  clearErrors('num_nf');
                }
              }}
              isError={!!errors.num_nf}
            />
          </Col>
          <Col>
            <div
              style={{
                display: 'flex',
                gap: '0.625rem',
                marginTop: '1.78rem',
              }}
            >
              <CustomButtonNovo
                disabled={isDisabled}
                onClick={handleSubmit}
                label="Pesquisar"
                icon={IoIosSearch}
                width="8.125rem"
                showLoading={loading}
              />
              <CustomButtonNovo
                disabled={loading}
                variant="cancel"
                label="Cancelar"
                icon={MdOutlineCancel}
                width="8.125rem"
                onClick={handleReset}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            sm={12}
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignContent: 'end',
            }}
          >
            {loading ? (
              <small
                style={{
                  color: '#39bf34',
                }}
              >
                CONSULTANDO NFE NA SEFAZ
              </small>
            ) : (
              ''
            )}
          </Col>
          <Col>
            <TableNF
              nfs={nf}
              setCod_seq={setCod_seq}
              onSelectedNF={onSelectedNF}
              selectionModel={selectionModel}
              setSelectionModel={setSelectionModel}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={8} lg={12}>
            <InputText
              name="motivo"
              label="Motivo"
              register={register}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                clearErrors('motivo');
                setValue('motivo', value);
              }}
              isError={!!errors.motivo}
              maxLength={512}
            />
          </Col>
          <Col>
            <div
              style={{
                display: 'flex',
                justifyItems: 'center',
                width: '100%',
                gap: '10px',
                marginTop: '1.875rem',
              }}
            >
              <Button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '23rem',
                  minWidth: '10rem',
                  height: '100%',
                }}
                className="button-action"
                variant="success"
                disabled={(tipoRotina === 0 && nf.length === 0) || loading}
                onClick={() => {
                  tipoRotina === 0
                    ? handleSubmitCancel()
                    : handleSubmitInutilizacao();
                }}
              >
                <BsFillLightningChargeFill size={20} />
                {label}
              </Button>
            </div>
          </Col>
        </Row>
        <SeparatorLine
          style={{
            marginBottom: '0px',
          }}
        />
      </Row>
      <FooterDefault codTela={211} />
    </Container>
  );
};

export default CancelamentoDeNota;
